import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import About from "@/views/About.vue";
import BreastfeedingWorkshop from "@/views/BreastfeedingWorkshop.vue";
import BreastfeedingWorkshopOnline from "@/views/BreastfeedingWorkshopOnline.vue";
import ParentalWorkshopOnline from "@/views/ParentalWorkshopOnline.vue";
import BabyMumCircle from "@/views/BabyMumCircle.vue";
import Booking from "@/views/Booking.vue";
import Home from "@/views/Home.vue";
import KangarooBabyWorkshop from "@/views/KangarooBabyWorkshop.vue";
import Toolbox from "@/views/Toolbox.vue";
import ToolboxRessources from "@/views/ToolboxRessources.vue";
import ToolboxKangorouBabySecurity from "@/views/ToolboxKangorouBabySecurity.vue";
import ToolboxKangorouBaby from "@/views/ToolboxKangorouBaby.vue";
import ToolboxBreastfeeding from "@/views/ToolboxBreastfeeding.vue";
import ToolboxPasswordKangorouBaby from "@/views/ToolboxPasswordKangorouBaby.vue";
import ToolboxMeitai from "@/views/ToolboxMeitai.vue";
import ToolboxSling from "@/views/ToolboxSling.vue";
import ToolboxWovenScarf from "@/views/ToolboxWovenScarf.vue";
import ToolboxSecurity from "@/views/ToolboxSecurity.vue";
import LegalNotices from "@/views/LegalNotices.vue";
import Rebozo from "@/views/Rebozo.vue";
import NotFound from "@/views/NotFound.vue";
import Agenda from "@/views/Agenda.vue";
import ToolboxBreastfeedingFollow from "@/views/ToolboxBreastfeedingFollow.vue";
import ToolboxPasswordBreastfeedingFollow from "@/views/ToolboxPasswordBreastfeedingFollow.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: 'Little Cocon - Facilitatrice en périnatalité - Montpellier / Nîmes',
      metaTags: [
        {
          name: 'description',
          content: 'Ateliers de portage bébé, consultation en allaitement et soutien à la maternité sur la région de Montpellier et Prades-le-Lez.'
        },
        {
          property: 'og:description',
          content: 'Ateliers de portage bébé, consultation en allaitement et soutien à la maternité sur la région de Montpellier et Prades-le-Lez.'
        }
      ]
    }
  },
  {
    path: "/soin-rituel-rebozo",
    name: "Rebozo",
    component: Rebozo,
    meta: {
      title: 'Little Cocon - Soins et rituel Rebozo - Montpellier / Nîmes',
      metaTags: [
        {
          name: 'description',
          content: 'Massage et serrage du bassin en post-partum. Rituel complet pour prendre soin de soi et se réapproprier son corps après l\'accouchement.'
        },
        {
          property: 'og:description',
          content: 'Massage et serrage du bassin en post-partum. Rituel complet pour prendre soin de soi et se réapproprier son corps après l\'accouchement.'
        }
      ]
    }
  },
  {
    path: "/atelier-portage-physiologique",
    name: "KangarooBabyWorkshop",
    component: KangarooBabyWorkshop,
    meta: {
      title: 'Little Cocon - Atelier de portage bébé - Montpellier / Nîmes',
      metaTags: [
        {
          name: 'description',
          content: 'Des ateliers pour essayer divers moyens de portage tout en respectant la physiologie de bébé. Pour découvrir/approfondir les techniques de façon sécuritaire.'
        },
        {
          property: 'og:description',
          content: 'Des ateliers pour essayer divers moyens de portage tout en respectant la physiologie de bébé. Pour découvrir/approfondir les techniques de façon sécuritaire.'
        }
      ]
    }
  },
  {
    path: "/consultation-allaitement",
    name: "BreastfeedingWorkshop",
    component: BreastfeedingWorkshop,
    meta: {
      title: 'Little Cocon - Consultation allaitement IBCLC - Montpellier / Nîmes',
      metaTags: [
        {
          name: 'description',
          content: 'Consultante IBCLC en lactation, je vous accompagne de la grossesse au sevrage, au sein et au biberon.'
        },
        {
          property: 'og:description',
          content: 'Consultante IBCLC en lactation, je vous accompagne de la grossesse au sevrage, au sein et au biberon.'
        }
      ]
    }
  },
  /*{
    path: "/consultation-allaitement-en-ligne",
    name: "BreastfeedingWorkshopOnline",
    component: BreastfeedingWorkshopOnline,
    meta: {
      title: 'Little Cocon - Consultation allaitement IBCLC - En ligne',
      metaTags: [
        {
          name: 'description',
          content: 'Consultante IBCLC en lactation, je vous accompagne en visio de la grossesse au sevrage, au sein et au biberon.'
        },
        {
          property: 'og:description',
          content: 'Consultante IBCLC en lactation, je vous accompagne en visio de la grossesse au sevrage, au sein et au biberon.'
        }
      ]
    }
  },
  {
    path: "/consultation-parentalite-en-ligne",
    name: "ParentalWorkshopOnline",
    component: ParentalWorkshopOnline,
    meta: {
      title: 'Little Cocon - Consultation sur la parentalité - En ligne',
      metaTags: [
        {
          name: 'description',
          content: 'Devenir parent est une aventure unique. Profitez d\'un coaching parental en visio pour vous accompagner durant la période périnatale (0-1 an).'
        },
        {
          property: 'og:description',
          content: 'Devenir parent est une aventure unique. Profitez d\'un coaching parental en visio pour vous accompagner durant la période périnatale (0-1 an).'
        }
      ]
    }
  },*/
  {
    path: "/cercles-mamans-bebes",
    name: "BabyMumCircle",
    component: BabyMumCircle,
    meta: {
      title: 'Little Cocon - Cercles mamans bébés - Montpellier / Nîmes',
      metaTags: [
        {
          name: 'description',
          content: 'Vous souhaitez rencontrer des mamans autour de vous ? Les cercles mamans bébés permettent d\'adoucir le quatrième trimestre des jeunes mères en apportant soutien et bienveillance.'
        },
        {
          property: 'og:description',
          content: 'Vous souhaitez rencontrer des mamans autour de vous ? Les cercles mamans bébés permettent d\'adoucir le quatrième trimestre des jeunes mères en apportant soutien et bienveillance.'
        }
      ]
    }
  },
  {
    path: "/a-propos",
    name: "About",
    component: About,
    meta: {
      title: 'Little Cocon - À propos',
      metaTags: [
        {
          name: 'description',
          content: 'À propos de moi'
        },
        {
          property: 'og:description',
          content: 'À propos de moi'
        }
      ]
    }
  },
  {
    path: "/agenda",
    name: "Agenda",
    component: Agenda,
    meta: {
      title: 'Little Cocon - Agenda',
      metaTags: [
        {
          name: 'description',
          content: 'Les prochaines dates'
        },
        {
          property: 'og:description',
          content: 'Les prochaines dates'
        }
      ]
    }
  },
  {
    path: "/mentions-legales",
    name: "LegalNotices",
    component: LegalNotices,
    meta: {
      title: 'Little Cocon - Mentions légales'
    }
  },
  {
    path: '/reservation',
    name: "Réservation",
    component: Booking,
  },
  {
    path: "/boite-outils",
    name: "Boite à outils",
    component: Toolbox
  },
  {
    path: '/boite-outils/ressourcerie',
    name: "Resssourcerie",
    component: ToolboxRessources,
  },
  {
    path: '/boite-outils/allaitement',
    name: "Allaitement",
    component: ToolboxBreastfeeding,
  },
  {
    path: '/boite-outils/password-suivi-allaitement',
    name: "Boite à outils - Mot de passe suivi allaitement",
    component: ToolboxPasswordBreastfeedingFollow,
  },
  {
    path: '/boite-outils/suivi-allaitement',
    name: "Allaitement",
    component: ToolboxBreastfeedingFollow,
  },
  {
    path: '/boite-outils/password-portage-physiologique',
    name: "Boite à outils - Mot de passe portage physiologique",
    component: ToolboxPasswordKangorouBaby,
  },
  {
    path: '/boite-outils/portage-physiologique',
    name: "Boite à outils - Portage physiologique",
    component: ToolboxKangorouBaby,
  },
  {
    path: '/boite-outils/boite-outils-securite-tissus',
    name: "Boite à outils - La sécurité",
    component: ToolboxSecurity,
  },
  {
    path: '/boite-outils/boite-outils-mei-tai',
    name: "Boite à outils - Le Mei-Tai",
    component: ToolboxMeitai,
  },
  {
    path: '/boite-outils/boite-outils-echarpe-tissee',
    name: "Boite à outils - L'écharpe tissée",
    component: ToolboxWovenScarf,
  },
  {
    path: '/boite-outils/boite-outils-sling',
    name: "Boite à outils - Le Sling",
    component: ToolboxSling,
  },
  {
    path: "/404",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: 'Little Cocon - Page introuvable'
    }
  },
  {
    path: "*",
    beforeEnter (to) {
      window.location.href = '/404'
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
    return {x: 0, y: 0}
  },
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => {
    if(el !== null && el.parentNode !== null) {
      el.parentNode.removeChild(el)
    }
  });

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router;
