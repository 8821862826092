






































import $ from 'jquery';
import { Component, Prop, Vue } from "vue-property-decorator";

interface Link {
    path: string;
    text: string;
    textShort: string;
}

@Component
export default class Toolbox extends Vue {
}
