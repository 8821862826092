






















































































import emailjs from 'emailjs-com';
import { Component, Prop, Vue } from "vue-property-decorator";
emailjs.init('user_aaqDUBiTMkAoD5I4S1yyM')

@Component
export default class Footer extends Vue {
  private formSubmitting: boolean = false;
  private formSubmitted: boolean = false;
  private phone: string = '';
  private mail: string = '';
  private message: string = '';

  goToPhone() {
    window.location.href = "tel:06.31.57.07.59";
  }

  goToEmail() {
    window.location.href = "mailto:laetitia@littlecocon.fr";
  }

  sendMessage() {
    try {
      this.formSubmitting = true
      emailjs.send('LITTLE_COCON', 'LITTLE_COCON_TEMPLATE', {
        contact: this.phone + " - " + this.mail,
        message: this.message
      }).then((response) => {
        this.formSubmitting = false
        this.formSubmitted = true
        console.log('SUCCESS You just sent an email...', response)
      }, (error) => {
        this.formSubmitting = false
        console.log('FAILED...', error)
      })
    } catch(error) {
      console.log({error})
    }
    this.phone = ''
    this.mail = ''
    this.message = ''
  }
}
